import React, { Component, useEffect, useState, useRef } from "react";
import {
  Button,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import "../Pages/Authorize.css";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Table from "../Pages/Table";
import HomeIco from "../images/HomeIco.png";
import api from "../main/ApiURL";
import { jwtDecode } from "jwt-decode";


function AuthorizeP() {
  const [showPassword, setShowPassword] = useState(false);
  const [selectList, setSelectList] = useState([]);
  const [token, setToken] = useState("");
  const [indicator, setIndicator] = useState(false);
  const [errAutorize, setErrAutorize] = useState(false);

  useEffect(() => {
    getSelectUser();
    const tok = localStorage.getItem("KAItoken");
    if (tok) {
      setToken(tok);
      setIndicator(true);
    } else {
      setToken("");
    }
  }, []);

  useEffect(() => {
    if (token !== "") {
      const intervalId = setInterval(() => {
        const tok = localStorage.getItem("KAItoken");
        const decoded = jwtDecode(tok);
        // console.log("token ", decoded);
        if (decoded.exp * 1000 < Date.now()) {
          localStorage.removeItem("KAItoken");
          localStorage.removeItem("userParams");
          setToken(""); 
          clearInterval(intervalId); 
          window.location.href=`${api.URLclient}/`;
        }
      }, 600000);
      return () => clearInterval(intervalId); 
    }
  }, [token]);

  const getSelectUser = async () => {
    try {
      const response = await axios.get(
        `${api.URLserv}/GetUsersSelect/usersselect`
      );
      if (response.status === 200 && response.data.length > 0) {
        // console.log("response users ", response);
        setSelectList(response.data);
      } else {
        setSelectList([]);
      }
    } catch (error) {
      setSelectList([]);
    }
  };

  const AutorizeF = async (data) => {
    try {
      const response = await axios.post(
        `${api.URLserv}/GetUsersSelect/authorize`,
        data
      );
      if (response.status === 200) {
        // console.log("autorize response", response);
        const data = response.data;
        localStorage.setItem("KAItoken", data.token);
        setToken(data.token);
        if (data.autorize.result === false) {
          setErrAutorize(true);
        }
        // console.log("autorize ", data.autorize.result);
        window.location.href = `${api.URLclient}/userstable`;
      } else {
        setErrAutorize(true);
      }
    } catch (error) {}
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    var id = parseInt(data.id);
    var selectedUser = selectList.find((user) => user.id === id);
    var name = selectedUser.name;
    var password = data.password;
    localStorage.setItem("userParams", JSON.stringify({ "id": id, "name": name }));

    data = {
      Id: id,
      Password: password,
      Name: name,
    };

    // console.log(data);

    await AutorizeF(data);
  };

  return (
    <>
      {indicator ? (
        <div className="autorizationOk">
          <span className="autorizationText">Ви вже авторизовані!</span>
          <a
             href={`${api.URLclient}/userstable`}
            className="autorizationLink"
          >
            Перейти до таблиці
          </a>
        </div>
      ) : (
        <Container className="ContainerMain">
          <Form onSubmit={handleSubmit(onSubmit)} className="AuthorizeForm">
            <h2 className="TitleFormS">Авторизація</h2>
            <Form.Group className="mb-3">
              <Form.Label>Оберіть місце реєстрації</Form.Label>
              <Form.Select {...register("id", { required: true })}>
                <option value="">Місце реєстрації</option>
                {selectList && selectList.map((obj, index) => (
    <option key={index} value={obj.id ? obj.id : ""}>
      {obj.name ? obj.name : ""}
    </option>
  ))}
              </Form.Select>
              {errors.id && (
                <Form.Text className="text-danger">Оберіть місце входу!</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Пароль"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                })}
              />
             {errors.password && errors.password.type === "maxLength" && (
              <Form.Text className="text-danger">
              Максимальна довжина пароля - 25 символів!
              </Form.Text>
                )}
              {errors.password && errors.password.type === "required" && (
                <Form.Text className="text-danger">
                  Поле "Пароль" є обов'язковим для заповнення!
                </Form.Text>
                  )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
              id="active-checkbox"
                type="checkbox"
                label="Показати пароль"
                onChange={() => setShowPassword(!showPassword)}
              />
            </Form.Group>
            <Button className="AuthorizeBtn" type="submit">
              Авторизувати
            </Button>
            {errAutorize ? (
              <span className="text-center mt-2 text-danger">
                Невірно введені дані авторизації
              </span>
            ) : (
              <span></span>
            )}
          </Form>
        </Container>
      )}
    </>
  );
}

export function ExitButton({ exit }) {
  return (
    <div className="BrandContainer">
    <Navbar.Brand onClick={exit} className="headerBrand">
      <img src={HomeIco} alt="Вийти" width={35}></img>
    </Navbar.Brand>
    <span className="headerBrand spyText">Вийти</span>
    </div>
  );
}
export default AuthorizeP;
