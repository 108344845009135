import "./App.css";
import { Link, Route, Routes, Navigate } from "react-router-dom";
import Authorize from "./Pages/Authorize";
import Table from "./Pages/Table";

function App() {
  return (
    <div className="AppMain">
      <Routes>
        <Route path="/" element={<Authorize />} />
        <Route path="/userstable" element={<Table />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
