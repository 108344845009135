import React, { Component, useEffect, useState, useRef } from "react";
import { Button, Container } from "react-bootstrap";
import "../Pages/Table.css";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { jwtDecode } from "jwt-decode";
import api from "../main/ApiURL";
import axios from "axios";
import moment from 'moment';

function TableP() {
  const [valueTimeStart, setValueTimeStart] = useState(new Date());
  const [valueTimeEnd, setValueTimeEnd] = useState(new Date());
  const [trStyleSelectRow, setTrStyleRow] = useState(null);
  const [token, setToken] = useState("");
  const [indicator, setIndicator] = useState(false);
  const [checkedInp, setCheckedInp] = useState(true);
  const [userParam, setUserParam] = useState({});
  const [listPlaces, setListPlaces] = useState([]);

  useEffect(() => {
    const tok = localStorage.getItem("KAItoken");
    const param = localStorage.getItem("userParams");
    if (tok) {
      setToken(tok);
    } else {
      setToken("");
    }
    if(param){
      const idUser = JSON.parse(param);
      setUserParam(JSON.parse(param));
      const startDate = formatDate(valueTimeStart);
      const endDate = formatDate(valueTimeEnd);
      getSession(startDate, endDate, idUser.id, checkedInp);
      setTrStyleRow(null);
    }
  }, []);

  useEffect(() => {
    if (token !== "") {
      setIndicator(true);
      const intervalId = setInterval(() => {
        const tok = localStorage.getItem("KAItoken");
        const decoded = jwtDecode(tok);
        // console.log("token ", decoded);
        if (decoded.exp * 1000 < Date.now()) {
          localStorage.removeItem("KAItoken");
          localStorage.removeItem("userParams");
          setToken(""); 
          setIndicator(false); 
          clearInterval(intervalId); 
          window.location.href=`${api.URLclient}/`;
        }
      }, 600000);
      return () => clearInterval(intervalId); 
    }
  }, [token]);

  const formatDate = (datePickerRow)=>{

    const date = new Date(datePickerRow);

    const month = (date.getMonth() + 1).toString().padStart(2, "0"); 
    const day = date.getDate().toString().padStart(2, "0"); 
    const year = date.getFullYear();

    const formattedDateStart = `${month}-${day}-${year}`;
    return formattedDateStart;
  };

  const getSession = async (dS, dE, id, act)=>{
        const active = act===true ? 1 : 0;
        // console.log(dS, dE, id, active); 

      try {
        const response = await axios.get(
          `${api.URLserv}/GetUsersSelect/getenterout?dateIn=${dS}&dateOut=${dE}&idPlace=${id}&active=${active}`);
        if (response.status === 200 && response.data.length > 0) {
        // console.log("response list ", response); 
          setListPlaces(response.data);
      setTrStyleRow(null);
        } else {
          setListPlaces([]);
      setTrStyleRow(null);
        }
      } catch (error) {
        console.log("Помилка ", error);
      }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const id = userParam.id;
    const active = data.active;
    var dateStart = formatDate(valueTimeStart);
    var dateEnd = formatDate(valueTimeEnd);
    // console.log(dateStart, dateEnd, id, active); 
    await getSession(dateStart, dateEnd, id, active)
  };

  return (
    <>
      {indicator ? (
        <Container fluid className="ContainerFluidMain">
          <div className="filterBlock">
            <Form onSubmit={handleSubmit(onSubmit)} className="filterForm">
              <span className="flilterTextS styleSecond">
                Дата входу/виходу
              </span>
              <Form.Group className="d-flex">
                <span className="flilterTextS">з</span>
                <DatePicker
                  onChange={setValueTimeStart}
                  value={valueTimeStart}
                  format="dd.MM.yyyy"
                />
              </Form.Group>
              <Form.Group className="d-flex">
                <span className="flilterTextS">по</span>
                <DatePicker onChange={setValueTimeEnd} value={valueTimeEnd} format="dd.MM.yyyy"/>
              </Form.Group>
              <Form.Group className="ms-2 d-flex">
                <Form.Label className="me-2  mb-0">Активні сесії</Form.Label>
                <Form.Check id="active-checkbox" className="checkedStyle" {...register("active")} checked={checkedInp} onChange={(e) => {
                  setCheckedInp(e.target.checked);
               }}/>
              </Form.Group>
              <Button className="filterBtn" type="submit">
                Пошук
              </Button>
            </Form>
          </div>
          <table className="usersTable">
            <thead className="theadTable">
              <tr>
                <th className="thStyle styleNumb">№ з/п</th>
                <th className="thStyle">Місце реєстрації</th>
                <th className="thStyle">Користувач</th>
                <th className="thStyle">З комп'ютера</th>
                <th className="thStyle">Увійшов</th>
                <th className="thStyle">Вийшов</th>
                <th className="thStyle">Версія</th>
                <th className="thStyle">Дата створення версії</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8} style={{ paddingTop: "2px" }}></td>
              </tr>
              {listPlaces.map((obj, index)=>(
                <tr key={index}
                className={index === trStyleSelectRow  ? "trBodyStyleSelect" : "trBodyStyle"}
                onClick={() => {
                  setTrStyleRow(index);
                }}
              >
                <td className="tdStyle styleNumb">{index+1}</td>
                <td className="tdStyle stylePlace">{obj.placeregistrationname}</td>
                <td className="tdStyle styleUser">
                  {obj.username}
                </td>
                <td className="tdStyle styleComp">{obj.hostName}</td>
                <td className="tdStyle styleEnter">{moment(obj.date_Enter).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td className="tdStyle styleExit">{obj.date_Exit !== null ? moment(obj.date_Exit).format('DD-MM-YYYY HH:mm:ss') : ""}</td>
                <td className="tdStyle styleVers">{obj.exe_vers}</td>
                <td className="tdStyle styleDate">{moment(obj.exe_datetime).format('DD-MM-YYYY HH:mm:ss')}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </Container>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default TableP;
