import React, { Component, useEffect, useState, useRef } from "react";
import {
  Button,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import "../headerFooter/HeadFoot.css";
import { ExitButton } from "../Pages/Authorize";
import HelpIco from "../images/HelpIco.png";
import api from "../main/ApiURL";

function HeaderComp() {
  const [userParam, setUserParam] = useState("");
  const [btnIndicate, setBtnIndicate] = useState(false);
// console.log(userParam);
  useEffect(() => {
    tokenInfo();
  }, []);

  const exit = () => {
    localStorage.removeItem("KAItoken");
    localStorage.removeItem("userParams");
    setBtnIndicate(false);
    window.location.href = `${api.URLclient}/`;
  };

  const tokenInfo = () => {
    const tok = localStorage.getItem("KAItoken");
    const userparam = JSON.parse(localStorage.getItem("userParams"));
    if (tok !== null) {
      setBtnIndicate(true);
      setUserParam(userparam);
    } else {
      setBtnIndicate(false);
      setUserParam("");
    }
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary headerStyle">
      <Container>
        {btnIndicate ? (
          <ExitButton
            exit={() => {
              exit();
            }}
          />
        ) : (
          ""
        )}
        <span className="white-space-wrap">{userParam.name}</span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div className="helpContainer">
            <Nav.Link href="https://kai.ua/contacts" target="_blanc" className="headerBrand ms-auto">
            <img src={HelpIco} alt="Допомога" width={35}></img>
            </Nav.Link>
            <span className="headerBrand spyTextHelp">Допомога</span>
            </div>
            {/* <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderComp;
