import React, { Component, useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";

function FooterComp() {
  return (
    <div className="FooterDiv">
      <Container>
      <span className="footSpanStyle">
        <a href="https://kai.ua/" className="brandText" target="_blank">
          © KAI
        </a>
        {/* <span className="spanYear brandText">2024 р.</span> */}
      </span>
      </Container>
    </div>
  );
}

export default FooterComp;
